import { createApp } from 'vue';
import { createHead } from '@unhead/vue'; // Import createHead
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import FlashMessage from '@smartweb/vue-flash-message';
import ChatComponent from '@/components/ChatComponent.vue';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/vendors/all.min.css';
import './assets/css/vendors/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/temp.css';
import './assets/css/responsive.css';    
import './assets/slick/slick.css';
import './assets/css/custom.css';
import './assets/css/custom_responsive.css';
import './assets/js/bootstrap.min.js';
import './assets/js/scripts.js';
import './assets/slick/slick.js';
import './assets/menu/script.js';

// Initialize head
const head = createHead();

const app = createApp(App)
    .use(head) // Use head plugin
    .use(store)
    .use(router);

app.component('ChatComponent', ChatComponent);

app.mount('#app');
