<template>
    <div class="chat-container">
        <div class="chat-toggle" @click="toggleChat">
            <span class="chat-icon">💬</span>
        </div>

        <div v-if="isChatOpen">
            <div class="support-header">
                <img src="../assets/images/logo/favicon.png" alt="Ethnic Meal" class="logo" />
                <h2>Welcome to Ethnic Meal Support</h2>
                <p>Search our Knowledge Base or start a chat. We're here to help 24/7.</p>
            </div>
            
            <div class="live-chat-box" v-if="$store.getters.isAuthenticated">   
                <!-- <ul class="messages">
                    <li v-for="(message, index) in messages" :key="index" :class="{'sender-message': message.user_id === currentUser.id, 'receiver-message': message.user_id !== currentUser.id}">
                        <div class="message">
                            <div class="message-content">
                                <div>{{ message.message }}</div>
                                <span class="message-time">{{ formatTime(message.created_at) }}</span>
                            </div>
                        </div>
                    </li>
                </ul> -->
                <ul class="messages">
                    <li v-for="(message, index) in messages" :key="index" :class="{
                        'sender-message': message.user_id === currentUser.id,
                        'receiver-message': message.user_id !== currentUser.id && !message.is_admin,
                        'admin-message': message.is_admin
                    }">
                        <div class="message">
                            <div class="message-content">
                                <div>{{ message.message }}</div>
                                <span class="message-time">{{ formatTime(message.created_at) }}</span>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="input-container">
                    <textarea
                        v-model="newMessage"
                        rows="1"
                        placeholder="Type a message..."
                        @keyup.enter="sendMessage"
                        @input="adjustTextareaHeight"
                    ></textarea>
                    
                    <button @click="sendMessage" :disabled="!newMessage.trim()">Send</button>
                </div>                    
            </div> 
            <div class="live-chat-box" v-else>
                Please login to start chat
            </div>             
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';
import echo from '../plugins/pusher';

export default {
    data() {
        return {
            messages: [],
            newMessage: '',
            isChatOpen: false,
            currentUser: {
                id: 2, // Simulated user ID for now
            },
        };
    },
    created() {
        if(this.$store.getters.isAuthenticated){
            echo.channel('chat')
                .listen('.message.sent', (data) => {
                    this.messages.push(data.message);
                });

            this.fetchMessages();
        }
    },
    beforeUnmount() {
        echo.leave('chat');
    },
    methods: {
        toggleChat() {
            this.isChatOpen = !this.isChatOpen;
        },

        fetchMessages() {
            axios.get('chat/messages')
                .then((response) => {
                    this.messages = response.data;
                })
                .catch((error) => {
                    console.error("Error fetching messages:", error);
                });
        },

        sendMessage() {
            if (this.newMessage.trim() !== '') {
                const messageData = {
                    user_id: this.currentUser.id,
                    message: this.newMessage,
                };

                axios.post('chat/send', messageData)
                    .then((response) => {
                        console.log("Message sent:", response.data);
                        this.messages.push(response.data.message);
                        this.newMessage = '';
                    })
                    .catch((error) => {
                        console.error("Error sending message:", error);
                    });
            }
        },

        formatTime(timestamp) {
            const date = new Date(timestamp);
            return `${date.getHours()}:${date.getMinutes()}`;
        },

        // Adjust height of the input box when typing
        adjustTextareaHeight() {
            this.$nextTick(() => {
                const textarea = this.$el.querySelector('textarea');
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            });
        }
    }
};
</script>

<style scoped>
.chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    z-index: 1000;
    font-family: 'Arial', sans-serif;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin-bottom: 60px;
}

.chat-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #c4520c;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 28px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    z-index: 1001;
}

.live-chat-box {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 500px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.messages {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.messages li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sender-message {
    justify-content: flex-end;
}

.receiver-message {
    justify-content: flex-start;
}

.message {
    background-color: #e1e1e1;
    padding: 8px;
    border-radius: 10px;
    max-width: 80%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sender-message .message {
    background-color: #e5aa86;
    color: #413e3e !important;
    font-size: 14px !important;
}

.message-content {
    display: flex;
    flex-direction: column;
}

.message-time {
    font-size: 10px;
    color: #f5f5f5;
    margin-top: 2px;
    text-align: right;
}

.input-container {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

textarea {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ddd;
    width: 80%;
    box-sizing: border-box;
    font-size: 14px;
    resize: none;
    transition: height 0.2s ease;
    overflow: hidden;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

button:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}


.support-header {
    background-color: #c4520c;
    color: white;
    text-align: center;
    padding: 10px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Arial', sans-serif;
}

.support-header .logo {
    width: 15%;
    height: auto;
    margin-bottom: 15px;
}

.support-header h2 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 5px;
}

.support-header p {
    font-size: 12px;
    font-weight: 400;
    margin: 0 auto;
    color: #f5f5f5;
}

.support-header p a {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.support-header p a:hover {
    color: #ffd700;
}
.admin-message .message {
    background-color: #007bff;
    color: #fff;
}
</style>
