// src/echo.js

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: 'pusher',
    key: '34b02e5ccb7091890edd',        // Replace with your Pusher app key
    cluster: 'ap2',    // Replace with your Pusher app cluster
    forceTLS: true                     // Use true for secure connections (recommended)
});

export default echo;
