<template> 
        <div>
            <a class="cart-button cart-button-style" href="#">
                <div class="bag-count">
                    <i class="fa-solid fa-bag-shopping"></i>
                    <span v-if="$store.getters.updateMiniCart.length > 0">{{ $store.getters.updateMiniCart.length }} Items</span>
                    <span v-else>{{ cartItems.length }} Items</span>
                </div>
                <span class="bag-label" v-if="$store.getters.updateSubTotal!=''">${{ Number($store.getters.updateSubTotal).toFixed(2) }}</span>
                <span class="bag-label" v-else>${{ Number(subtotal).toFixed(2) }}</span>
            </a>

        <aside id="sidebar-cart">
            <main>
                <a href="#" class="close-button"><span class="close-icon" ref="closeBtn">X</span></a>
                <h2>Shopping Bag 
                    <span class="count" v-if="$store.getters.updateMiniCart.length > 0">{{ $store.getters.updateMiniCart.length }}</span>
                    <span class="count" v-else>{{ cartItems.length }}</span>
                    <span class="text-danger" title="Remove all item from bag" @click="deleteItems">
                        <i class="fa fa-trash"></i></span>
                </h2>
               

                <ul class="products" v-if="$store.getters.updateMiniCart.length > 0">                    
                    <li class="product" v-for="item in $store.getters.updateMiniCart" :key="item.id">
                        <a href="#" class="cartProductLink">
                            <span class="product-image">
                                <img v-bind:src="item.product.image" v-bind:title="item.product.name" v-bind:alt="item.product.name">
                            </span>
                            <span class="product-details">
                                <h3>{{ item.product.name.substring(0,20)+".." }}</h3>
                                <span class="qty-price">
                                    <span class="qty">
                                        <button class="minus-button" id="minus-button-1" @click="$store.dispatch('updateCart', {cart_id:item.id, product_id:item.product.id, quantity:'qtyOne'+item.id, action:'minus'})">-</button>
                                        <input type="number" class="qty-input" :id="'qtyOne'+item.id" step="1" min="1" max="1000"
                                        pattern="[0-9]*" v-bind:value="item.quantity" readonly/>
                                        <button class="plus-button" @click="$store.dispatch('updateCart', {cart_id:item.id, product_id:item.product.id, quantity:'qtyOne'+item.id, action:'plus'})">+</button>
                                    </span>
                                    <span class="price" :id="'priceOne'+item.id">${{ Number(item.product.product_inventory.price * item.quantity).toFixed(2) }}</span>
                                </span>
                            </span>
                        </a>
                        <a href="#" class="remove-button" @click="$store.dispatch('removeCart', item.id)">
                            <span class="remove-icon">X</span>
                        </a>
                    </li>    
                </ul>

                <ul class="products" v-else>     
                    <li class="product" v-for="(items) in cartItems" :key="items.id">
                        <a href="#" class="cartProductLink">
                            <span class="product-image">
                                <img v-bind:src="items.product.image" v-bind:title="items.product.name" v-bind:alt="items.product.name">
                            </span>
                            <span class="product-details">
                                <h3>{{ items.product.name.substring(0,20)+".." }}</h3>
                                <span class="qty-price">
                                    <span class="qty">
                                        <button class="minus-button" id="minus-button-1" @click="$store.dispatch('updateCart', {cart_id:items.id, product_id:items.product.id, quantity:'qtyTwo'+items.id, action:'minus'})">-</button>
                                        <input type="number" class="qty-input" :id="'qtyTwo'+items.id" step="1" min="1" max="1000"
                                        pattern="[0-9]*" v-bind:value="items.quantity" readonly/>
                                        <button class="plus-button" @click="$store.dispatch('updateCart', {cart_id:items.id, product_id:items.product.id, quantity:'qtyTwo'+items.id, action:'plus'})">+</button>
                                    </span>
                                    <span class="price" :id="'priceTwo'+items.id">${{ Number(items.product.product_inventory.price * items.quantity).toFixed(2) }}</span>
                                </span>
                            </span>
                        </a>
                        <a href="#" class="remove-button" @click="$store.dispatch('removeCart', items.id)">
                            <span class="remove-icon">X</span>
                        </a>
                    </li> 
                </ul>
                <div class="totals">
                    <div class="subtotal">
                        <span class="amountLabel">Subtotal:</span>
                        <span class="amount" v-if="$store.getters.updateSubTotal!=''">${{ Number($store.getters.updateSubTotal).toFixed(2) }}</span>
                        <span class="amount" v-else>${{ Number(subtotal).toFixed(2) }}</span>
                    </div>
                    <div class="shipping">
                        <span class="amountLabel">Shipping:</span>
                        <span class="amount">$0.0</span>
                    </div>
                    <div class="tax">
                        <span class="amountLabel">Tax:</span>
                        <span class="amount">$0.0</span>
                    </div>
                    <div class="amountPayable">
                        <span class="amountLabel">Amount Payable:</span>
                        <span class="amount" v-if="$store.getters.updateSubTotal!=''">${{ Number($store.getters.updateSubTotal).toFixed(2) }}</span>
                        <span class="amount" v-else>${{ Number(subtotal).toFixed(2) }}</span>
                    </div>
                    <div class="action-buttons">
                        <router-link :to="{ path: '#' }" @click="goToCheckout" v-if="$store.getters.updateMiniCart.length > 0 || cartItems.length > 0" class="checkout-button btn-primary">Proceed to checkout</router-link>
                        <router-link :to="{ path: '#'}" v-else class="checkout-button btn-default">Cart is Empty</router-link>
                    </div>
                </div>
                <!-- <div class="action-buttons">
                    <router-link :to="{ path: '#' }" @click="goToCheckout" v-if="$store.getters.updateMiniCart.length > 0 || cartItems.length > 0" class="checkout-button btn-primary">Proceed to checkout</router-link>
                    <router-link :to="{ path: '#'}" v-else class="checkout-button btn-default">Cart is Empty</router-link>
                </div> -->
            </main>
        </aside>
        </div>
</template>
<script>
import axios from '@/utils/axiosConfig';
export default {
    name: 'Minicart', 
    props: ['cartItems','subtotal'],
    methods: {
        goToCheckout(){
             if(this.$store.getters.isAuthenticated){
                this.$router.push('/shipping')
                var elem = this.$refs.closeBtn
                elem.click()
            }
            else{
                this.$router.push({ path: '/checkout-login' })
                var closeEl = this.$refs.closeBtn
                closeEl.click()
            }            
        },
        deleteItems() {
            axios.delete(`cart-delete-all`)
            .then(
            function (response) {
                // console.log(response)
                if (response.data.success) {
                    console.log('Deleted')
                    localStorage.clear();
                    window.location.reload()
                }
            })
            .catch(function (error) {
                console.log(error);
            });            
        }
    }
};
</script>

