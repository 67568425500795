import {
  createStore
}
from 'vuex'
import axios from '@/utils/axiosConfig';
import $ from 'jquery'
import router from "@/router";

// export default createStore({
  const state = {
    cartData: '',
    subTotal: '',
  };

  const getters = {
    updateMiniCart(state) {
      return state.cartData;
    },
    updateSubTotal(state) {
      return state.subTotal;
    }
  };

  const mutations = {
    updateMiniCart(state, cartData) {
      state.cartData = cartData
    },
    getSubTotal(state, subTotal) {
      state.subTotal = subTotal
    },
    updateCart(state, subTotal) {
      state.subTotal = subTotal
    },
    clearCart(state) {
      state.cartData = [];
      state.subTotal = 0;
    }
  };

  const actions = {
    buyNow({
      commit
    }, products) {
      let cardData = {
        'product_id': products.product_id,
        'seller_id': products.seller_id,
        'quantity': 1,
        'user_id': localStorage.getItem('user_id')
      };

      axios.post(`cart-store`, cardData)
        .then(
          function (response) {
            if (response.data.success) {
              let responseData = response.data.data
              let subtotal = 0
              commit('updateMiniCart', responseData)
              localStorage.setItem('miniCartItemTotal', responseData.length)

              responseData.forEach(p => {
                subtotal += p.product.product_inventory.price * p.quantity;
                localStorage.setItem('itemTotal', subtotal)
              });
              commit('getSubTotal', subtotal)
              router.push('/checkout')

            } else {
              alert(response.data);
            }
          })
        .catch(function (error) {
          console.log(error);
          // alert('Something wrong! Plese Check our API request');
        });
    },
    addToCart({
      commit
    }, products) {
      let qty = 1;
      
      if(Object.prototype.hasOwnProperty.call(products, 'quantity')){
        qty = parseInt($("#" + products.quantity).val());
      }
      let cardData = {
        'product_id': products.product_id,
        'seller_id': products.seller_id,
        'quantity': qty,
        'user_id': localStorage.getItem('user_id')
      };

      axios.post(`cart-store`, cardData)
        .then(
          function (response) {
            if (response.data.success) {
              let responseData = response.data.data
              let subtotal = 0
              commit('updateMiniCart', responseData)
              responseData.forEach(p => {
                subtotal += p.product.product_inventory.price * p.quantity;
                localStorage.setItem('itemTotal', subtotal)
              });
              commit('getSubTotal', subtotal)

            } else {
              alert(response.data);
            }
          })
        .catch(function (error) {
          console.log(error);
          alert('Something wrong! Plese Check our API request');
        });
    },
    updateCart({
      commit
    }, products) {
      let quantity = 0;
      if (products.action == 'plus') {
        quantity = parseInt($("#" + products.quantity).val()) + 1;
      } else if (products.action == 'minus') {
        quantity = parseInt($("#" + products.quantity).val()) - 1;
      }

      let cardData = {
        'product_id': products.product_id,
        'cart_id': products.cart_id,
        'quantity': quantity,
      };

      axios.patch(`cart-update/` + cardData.cart_id, cardData)
        .then(
          function (response) {
            if (response.data.success) {
              let responseData = response.data.data
              let subtotal = 0
              commit('updateMiniCart', responseData)
              responseData.forEach(p => {
                subtotal += p.product.product_inventory.price * p.quantity;
                localStorage.setItem('itemTotal', subtotal)
              });
              commit('getSubTotal', subtotal)

            } else {
              alert(response.data);
            }
          })
        .catch(function (error) {
          console.log(error);
          alert('Something wrong! Plese Check our API request');
        });
    },
    removeCart({
      commit
    }, cart_id) {

      axios.delete(`cart-delete/` + cart_id)
        .then(
          function (response) {
            if (response.data.success) {
              let responseData = response.data.data
              let subtotal = 0
              commit('updateMiniCart', responseData)
              responseData.forEach(p => {
                subtotal += p.product.product_inventory.price * p.quantity;
                localStorage.setItem('itemTotal', subtotal)
              });
              commit('getSubTotal', subtotal)

            } else {
              alert(response.data);
            }
          })
        .catch(function (error) {
          console.log(error);
          alert('Something wrong! Plese Check our API request');
        });
    },

    clearCart({ commit }) {
      commit('clearCart');
    }
  };
  
export default {
  state,
  getters,
  actions,
  mutations
};
  // modules: {}
// })