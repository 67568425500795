<template>  
<div>
    <head>
        <meta name="description" content="Free shipping on millions of items. Get the best of Shopping and Entertainment with Prime. Enjoy low prices and great deals on the largest selection of everyday essentials and other products, including fashion, home, beauty, electronics, Alexa Devices, sporting goods, toys, automotive, pets, baby, books, video games, musical instruments, office supplies, and more.">
        <meta name="keywords" content="Ethnic Meal, ethnicmeal.com, Books, Online Shopping, Book Store, Magazine, Subscription, Music, CDs, DVDs, Videos, Electronics, Video Games, Computers, Cell Phones, Toys, Games, Apparel, Accessories, Shoes, Jewelry, Watches, Office Products, Sports & Outdoors, Sporting Goods, Baby Products, Health, Personal Care, Beauty, Home, Garden, Bed & Bath, Furniture, Tools, Hardware, Vacuums, Outdoor Living, Automotive Parts, Pet Supplies, Broadband, DSL">
        <meta name="google" content="nositelinkssearchbox">
        <meta name="google-site-verification" content="9vpzZueNucS8hPqoGpZ5r10Nr2_sLMRG3AnDtNlucc4">
        <link rel="canonical" href="http://ethnicmeal.com/">
        <meta http-equiv="content-type" content="text/html; charset=UTF-8">
        <meta property="fb:app_id" content="164734381262">
        <meta name="twitter:card" value="summary">
        <meta name="twitter:site:id" value="20793816">
        <meta property="og:image" content="http://ethnicmeal.com/static/img/logo.38333aaa.png" xmlns:og="http://opengraphprotocol.org/schema/">
        <meta property="og:description" content="Free shipping on millions of items. Get the best of Shopping and Entertainment with Prime. Enjoy low prices and great deals on the largest selection of everyday essentials and other products, including fashion, home, beauty, electronics, Alexa Devices, sporting goods, toys, automotive, pets, baby, books, video games, musical instruments, office supplies, and more." xmlns:og="http://opengraphprotocol.org/schema/">
        <meta name="msvalidate.01" content="3C8D6512B1E530046DE0569BA27093F3" />
        <meta name="p:domain_verify" content="5de371cb20aa4232fe72f25d97e07dd6" />
    </head>    
</div>
</template>

<script>
import $ from 'jquery'
function toggleSidebar() {
    var element = document.getElementById("sidebar-wrapper");
    // element.classList.toggle("close-icon");
}

if (window.innerWidth < 991) {
    const initialAddedClass = document.getElementById("sidebar-wrapper");
    // initialAddedClass.classList.add("close-icon");
}
$(document).ready(function ($) {
    // Declare the body variable
    var $body = $("body");

    // Function that shows and hides the sidebar cart
    $(".cart-button, .close-button").click(function (e) {
        e.preventDefault();

        // Add the show-sidebar-cart class to the body tag
        $body.toggleClass("show-sidebar-cart");
    });

    // Function that adds or subtracts quantity when a 
    // plus or minus button is clicked
    $body.on('click', '.plus-button, .minus-button', function () {
        // Get quanitity input values
        var qty = $(this).closest('.qty').find('.qty-input');
        var val = parseFloat(qty.val());
        var max = parseFloat(qty.attr('max'));
        var min = parseFloat(qty.attr('min'));
        var step = parseFloat(qty.attr('step'));

        // Check which button is clicked
        if ($(this).is('.plus-button')) {
            // Increase the value
            qty.val(val + step);
        } else {
            // Check if minimum button is clicked and that value is 
            // >= to the minimum required
            if (min && min >= val) {
                // Do nothing because value is the minimum required
                qty.val(min);
            } else if (val > 0) {
                // Subtract the value
                qty.val(val - step);
            }
        }
    });
});

export default {
    name: 'Master',
}
</script>