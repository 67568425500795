import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/modules/auth';

// Lazy-loaded imports to improve performance
const Home = () => import('../views/Home.vue');
const Gallery = () => import('../views/Gallery.vue');
const ProductDetails = () => import('../views/ProductDetails.vue');
const Cart = () => import('../components/cart/Cart.vue');
const SignUp = () => import('../components/auth/SignUp.vue');
const SignIn = () => import('../components/auth/SignIn.vue');
const Profile = () => import('../components/auth/Profile.vue');
const MyOrder = () => import('../components/auth/MyOrder.vue');
const Content = () => import('../views/Content.vue');
const SellerGallery = () => import('../views/SellerGallery.vue');
const FastivalProduct = () => import('../views/FastivalProduct.vue');
const Search = () => import('../views/Search.vue');
const SellerSignup = () => import('../views/merchant_auth/SellerSignup.vue');
const Default = () => import('../views/Default.vue');
const Seller = () => import('../views/Seller.vue');
const FlashSaleGallery = () => import('../views/FlashSaleGallery.vue');
const Fastival = () => import('../views/Fastival.vue');
const FastivalDetails = () => import('../views/FastivalDetails.vue');
const ThankYou = () => import('../components/checkout/ThankYou.vue');
const CheckoutLogin = () => import('../views/checkout/Login.vue');
const Shipping = () => import('../views/checkout/ShippingAddress.vue');
const ShippingCost = () => import('../views/checkout/ShippingCost.vue');
const Payment = () => import('../views/checkout/Payment.vue');
const Checkout = () => import('../views/checkout/Checkout.vue');

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/gallery/:slug', name: 'Gallery', component: Gallery },
  { path: '/product-details/:code/:slug', name: 'ProductDetails', component: ProductDetails },
  { path: '/cart', name: 'Cart', component: Cart },
  { path: '/marketplace', name: 'marketplace', component: Seller },
  { path: '/default', name: 'Default', component: Default },
  { path: '/signup', name: 'SignUp', component: SignUp, meta: { guest: false } },
  { path: '/login', name: 'Login', component: SignIn, meta: { guest: false, requiresAuth: false } },
  { path: '/seller/signup', name: 'MerchantSignUp', component: SellerSignup },
  { path: '/content/:slug', name: 'Content', component: Content },
  { path: '/search', name: 'Search', component: Search },
  { path: '/seller-store/:slug', name: 'SellerGallery', component: SellerGallery },
  { path: '/fastival-product/:slug', name: 'FastivalProduct', component: FastivalProduct },
  { path: '/flash-sale', name: 'FlashSaleGallery', component: FlashSaleGallery },
  { path: '/fastival', name: 'Fastival', component: Fastival },
  { path: '/fastival/:slug', name: 'FastivalDetails', component: FastivalDetails },
  {
    path: '/checkout-login',
    name: 'CheckoutLogin',
    component: CheckoutLogin,
    meta: { guest: false, requiresAuth: false },
  },
  { path: '/shipping', name: 'Shipping', component: Shipping },
  { path: '/shipping-cost', name: 'ShippingCost', component: ShippingCost },
  { path: '/payment', name: 'Payment', component: Payment },
  { path: '/checkout', name: 'Checkout', component: Checkout },
  { path: '/thankyou', name: 'ThankYou', component: ThankYou },
  { path: '/profile', name: 'Profile', component: Profile, meta: { requiresAuth: true } },
  { path: '/my-order', name: 'MyOrder', component: MyOrder, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Router guard for authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.meta.guest && isAuthenticated) {
    next({ name: 'home' });
  } else {
    next();
  }
});

// After each navigation (optional for analytics or page tracking)
router.afterEach((to) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} - Your App Name`;
  }
});

export default router;
