import axios from '@/utils/axiosConfig';

// axios.defaults.withCredentials = true; 

const state = {
  user: null,
  // token: null,
  message: null,
  errors: [],
};
const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  // StateToken: (state) => state.token,
  StateMessage: (state) => state.message,
  StateErrors: (state) => state.errors,
};
const actions = {
  async Login({commit}, form) {
    let response = await axios.post(`login`, form);
    
    if (response.data.status) {      
      localStorage.removeItem('guest_id')
      localStorage.removeItem('isGuest')

      await commit("setUser", response.data.user);
      // await commit("setToken", response.data.access_token);
      // axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
    }    
    else{
      await commit("setMessages", response.data.message);
      await commit("setErrors", !response.data.status ? response.data.error : '');
    }
  },

  /* async LogOut({ commit }) {
    let user = null;
    let token = null;
    let datas = {
      user : user,
      token : token
    }
    commit("logout", datas);
  }, */

  async LogOut({ commit }) {
    try {
      await axios.post(`logout`); // Make sure to handle logout on the server side to invalidate token/cookie
      commit("logout", { user: null });
      // commit("logout", { user: null, token: null });
    } catch (error) {
      console.error('Logout error:', error);
      commit("setErrors", ['Logout failed. Please try again.']);
    }
  },

};
const mutations = {
  setErrors(state, errors) {
    state.errors = errors;
  },
  setMessages(state, message) {
    state.message = message;
  },

  setUser(state, username) {
    state.user = username;
  },

  /* setToken(state, token) {
    state.token = token;
  }, */
  
  logout(state, datas) {
    state.user = datas.user;
    // state.token = datas.token;
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};