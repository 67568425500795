<template>  
<div class="mmenus">
    <Master/>
    <div class="page-wrapper compact-wrapper mmenus" id="pageWrapper">        
        <div class="page-header">
            <div class="container">
                <div class="header-wrapper row align-items-center m-0 justify-content-between">
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="header-logo-wrapper p-0">
                            <div class="logo">
                                <router-link :to="{ name: 'home' }">
                                    <img class="img-fluid" src="../../assets/images/logo/logo.png" alt="Ethnic Meal" />
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                        <Search/>
                    </div>

                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="nav-right right-header p-0">
                            <ul class="nav-menus">
                                <li class="cart-nav" v-if="!$store.getters.isAuthenticated">
                                    <router-link :to="{ name : 'Login'}" class="cursor anchor">Sign In</router-link>
                                </li>
                                <li class="cart-nav" @click="handleLogout" v-if="$store.getters.isAuthenticated">
                                    <div class="cursor">Sign Out</div>
                                </li>
                                <li class="cart-nav" v-if="$store.getters.isAuthenticated">
                                    <div class="cursor"><router-link to="/profile"> My Account</router-link> </div>
                                </li>

                                <!-- <li class="cart-nav">
                                    <div class="deliverAddress">
                                        <a href="#">
                                            <i class="fa-regular fa-heart"></i>
                                            <span class="badge rounded-pill badge-primary" 
                                            style="right: 10px;top: 5px !important;">0</span>
                                        </a>
                                    </div>
                                </li> -->
                                <li class="cart-nav onhover-dropdown cart-button" >
                                    <div class="cart-box">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <g>
                                                    <path
                                                        d="M5.52377 7C9.41427 5.74386 13.9724 5.45573 16 5.5C18.0276 5.54427 18.8831 6.2663 19.5 7.5C20.5 9.5 20.289 14.4881 18.5 16.0871C16.712 17.6861 9.33015 17.8381 6.87015 16.0871C4.27115 14.2361 5.629 9.192 5.544 5.743C5.595 3.813 3.5 3.5 3.5 3.5"
                                                        stroke="#130F26" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round"></path>
                                                    <path d="M13 10.5H15.773" stroke="#130F26" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M7.26399 20.1274C7.56399 20.1274 7.80799 20.3714 7.80799 20.6714C7.80799 20.9724 7.56399 21.2164 7.26399 21.2164C6.96299 21.2164 6.71899 20.9724 6.71899 20.6714C6.71899 20.3714 6.96299 20.1274 7.26399 20.1274Z"
                                                        fill="#130F26" stroke="#130F26" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M17.5954 20.1274C17.8964 20.1274 18.1404 20.3714 18.1404 20.6714C18.1404 20.9724 17.8964 21.2164 17.5954 21.2164C17.2954 21.2164 17.0514 20.9724 17.0514 20.6714C17.0514 20.3714 17.2954 20.1274 17.5954 20.1274Z"
                                                        fill="#130F26" stroke="#130F26" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                </g>
                                            </g>
                                        </svg>
                                        <span class="badge rounded-pill badge-primary" v-if="$store.getters.updateMiniCart.length > 0">{{ $store.getters.updateMiniCart.length }}</span>
                                        <span class="badge rounded-pill badge-primary" v-else>{{ cartItems.length }}</span>
                                    </div>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-header">  
                <div class="container">
                    <Nav/>
                    <!-- <FlashMessage></FlashMessage> -->
                </div>               
            </div>
        </div>
    </div>
<Minicart :cartItems="cartItems" :subtotal="subtotal"/>
</div>
</template>

<script>
import axios from '@/utils/axiosConfig';
import Master from '@/components/include/Master.vue'
import Nav from '@/components/include/Nav.vue'
import Minicart from '@/components/include/Minicart.vue'
import Search from '@/components/include/Search.vue'

export default {
    name: 'Header',
    components: {    
        Master,
        Nav,
        Minicart,
        Search
    },
    data() {
        return {
            error: '',
            subtotal: 0,
            cartItems: [],
        }  
    },
    mounted() {
        this.getProduct();
        // this.storageTest()
    },
    methods: {/* 
        storageTest(){            
            let vuex = localStorage.getItem('vuex')
            console.log(vuex)
        }, */
        async handleLogout (){
            await this.$store.dispatch('LogOut')
            this.$router.push('/login')
        },
        getProduct() {
            axios.get(`mini-carts`).then((response) => {
            this.cartItems = response.data; 
            localStorage.setItem('cartItemTotal', this.cartItems.length)
            this.cartItems.forEach(p => {
                    this.subtotal += p.product.product_inventory.price * p.quantity;
                    localStorage.setItem('itemTotal', this.subtotal)
                });
            });
        }
    }
}
</script>