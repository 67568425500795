<template>  
    <div class="header-menu">
        <div class="header-menu-item-right">
          <button type="button" class="menu-mobile-trigger">
            <i class = "fas fa-bars"></i>
          </button>
        </div>

        <div class="header-menu-item-center">
          <div class="overlay"></div>
          <nav class="menu">
              <div class="menu-mobile-header">
								<button type="button" class="menu-mobile-arrow"><i class="fa fa-arrow-left"></i></button>
								<div class="menu-mobile-title"></div>
								<button type="button" class="menu-mobile-close"><i class="fa fa-close"></i></button>
							</div>


            <ul class="menu-section">
              <li><router-link :to="{ name: 'home'}"  class = "menu-link">Home</router-link></li>
              <ShopByCategory/>
              <Merchant/>
              <AccessoryProducts/>
              <li class="menu-item-has-children">
                <router-link :to="{ name: 'marketplace'}"  class = "menu-link">Sell with Us
                  <span class = "drop-icon"><i class = "fas fa-chevron-down"></i></span>
                </router-link>
                <div class="menu-subs menu-column-1">
                  <ul>
                    <li><router-link :to="{ name: 'marketplace'}">How It Works</router-link></li>
                    <li><a href="#">Benefits</a></li>
                    <li><router-link :to="{name : 'MerchantSignUp'}">Register as a Seller</router-link></li>
                  </ul>
                </div>
              </li>
              <li><a href="https://ethnicdish.com/" target="_blank" class = "menu-link">Blog</a></li>
            </ul>
          </nav>
        </div>
		</div>
</template>

<script>
import Merchant from './Merchant.vue'
import AccessoryProducts from './AccessoryProducts.vue'
import ShopByCategory from './ShopByCategory.vue'

export default {
  name: 'Nav',  
  mounted(){
    const menu = document.querySelector('.menu');
    const menuSection = menu.querySelector('.menu-section');
    const menuArrow = menu.querySelector('.menu-mobile-arrow');
    const menuClosed = menu.querySelector('.menu-mobile-close');
    const menuTrigger = document.querySelector('.menu-mobile-trigger');
    const menuOverlay = document.querySelector('.overlay');
    let subMenu;

    menuSection.addEventListener('click', (e) => {
      if (!menu.classList.contains('active')) {
          return;
      }

      if (e.target.closest('.menu-item-has-children')) {
          const hasChildren = e.target.closest('.menu-item-has-children');
          showSubMenu(hasChildren);
      }
    });

    menuArrow.addEventListener('click', () => {
      hideSubMenu();
    });

    menuTrigger.addEventListener('click', () => {
      toggleMenu();
    });

    menuClosed.addEventListener('click', () => {
      toggleMenu();
    });

    menuOverlay.addEventListener('click', () => {
      toggleMenu();
    });

    function toggleMenu() {
      menu.classList.toggle('active');
      menuOverlay.classList.toggle('active');
    }

    function showSubMenu(hasChildren) {
      subMenu = hasChildren.querySelector('.menu-subs');
      subMenu.classList.add('active');

      subMenu.style.animation = 'slideLeft 0.5s ease forwards';
      const menuTitle = hasChildren.querySelector('span').parentNode.childNodes[0].textContent;
      menu.querySelector('.menu-mobile-title').innerHTML = menuTitle;
      menu.querySelector('.menu-mobile-header').classList.add('active');
    }

    function hideSubMenu() {
      subMenu.style.animation = 'slideRight 0.5s ease forwards';
      setTimeout(() => {
          subMenu.classList.remove('active');
      }, 300);

      menu.querySelector('.menu-mobile-title').innerHTML = '';
      menu.querySelector('.menu-mobile-header').classList.remove('active');
    }

    window.onresize = function () {
      if (this.innerWidth > 991) {
          if (menu.classList.contains('active')) {
            toggleMenu();
          }
      }
    };
  },  
  components: {
    Merchant,
    AccessoryProducts,
    ShopByCategory
  }
};
</script>