<template>  
        <li class="menu-item-has-children">
          <router-link :to="{ name: 'marketplace'}"  class = "menu-link">Marketplace
          <span class = "drop-icon"><i class = "fas fa-chevron-down"></i></span></router-link>              
          <div class="menu-subs menu-mega menu-column-1">
                <ul>
                  <li v-for="(item) in merchants" :key="item.id">
                    <router-link :to="{ name: 'SellerGallery', params: { slug: item.business ? item.business.uniqueName : '#'} }">
                      {{ item.business ? item.business.name : item.name }}
                    </router-link>                       

                  </li>
                </ul>                  
          </div>
        </li>  
</template>

<script>
// import axios from '@/utils/axiosConfig';
import axios from '@/utils/axiosConfig';   //////////////// Axios Using for data send and receive with server

export default {
    name: 'Merchant',  
    data(){
        return{
        error: '',
        merchants: []
        }
    },
    mounted() {
        this.getMerchants();
    },
    methods:{   
        getMerchants() {
            axios.get(`merchants`).then((response) => {
                if(response.data.success){
                  this.merchants = response.data.data;
                }
                else{
                  this.error = 'URL Data Not Found';
                }            
            });
        },
    },
};
</script>