<template>
    <div class="search_area">     
        <form @submit.prevent="searchData">                      
            <input class="search_box" type="text" placeholder="Search here.." v-model="keywords" />
            <button id="al" type="submit" class="search_btn" aria-label="search" title="product search"><i class="fa-solid fa-magnifying-glass"></i></button>
        </form>
    </div>
</template>

<script>
import axios from '@/utils/axiosConfig';

export default {
    name: 'SignUp',  
    data(){
        return{
            keywords: ""
        }
    },
    methods:{  
        searchData(){  
            this.$router.push('/search/?s='+this.keywords+'')
        },
        /* async searchData(keywords){  
            let response = await axios.get(`search-suggestion`, keywords);
            if (response.data.success) {
                console.log(response.data.data)
            }
            else {
                console.log(response.data)
            }
        }, */
    },
};
</script>

