<template>  
  <li class="menu-item-has-children">
    <a href="#">Shop by Category <span class = "drop-icon"><i class = "fas fa-chevron-down"></i></span></a>
    <div class="container menu-subs menu-mega menu-column-4">
      <div class="list-item" v-for="(item) in categories" :key="item.id">
        <h4><router-link :to="{ path: '/gallery/'+item.slug }" class="title">{{ item.name }}</router-link></h4>
        
        <ul v-if="item.active_sub_categories.length >  0">
          <li v-for="(sub_item) in item.active_sub_categories" :key="sub_item.id">
            <router-link :to="{ path: '/gallery/'+sub_item.slug }">{{ sub_item.name }}</router-link>
          </li>
        </ul>
      </div>      
    </div>
  </li>
</template>

<script>
// import axios from '@/utils/axiosConfig';
import axios from '@/utils/axiosConfig';   //////////////// Axios Using for data send and receive with server

export default {
    name: 'ShopByCategory',  
    data(){
        return{
        error: '',
        categories: []
        }
    },
    mounted() {
        this.getCategories();
    },
    methods:{   
        getCategories() {
            axios.get(`homepage-categories?type=shop-by-category`).then((response) => {
                if(response.data.success){
                  this.categories = response.data.data;
                  // console.log(this.categories)
                }
                else{
                  this.error = 'URL Data Not Found';
                }            
            });
        },
  },
};
</script>