<template>
    <footer>
        <div class="top_footer">
            <img src="../../assets/icon/carrot.png" alt="Carrot" style="width: 25px; height: auto;" />
            100% locally sourced. Supporting local business is at our core.
        </div>
        <div class="footer footer-menu">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <img class="w-50" src="../../assets/images/logo/logo_dark.png" alt="Eth;nic Meal Dart Logo" />

                        <p>
                            Spice up your life with EthnicMeal.com! Your one-stop marketplace to buy and sell authentic
                            ethnic flavors.
                            Discover and savor global cuisine—one meal at a time.”
                        </p>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <h3 class="footer_header">Seller section</h3>
                        <ul>
                            <li><router-link :to="{ name: 'MerchantSignUp' }">Start selling</router-link></li>
                            <li><router-link :to="{ name: 'marketplace' }">How it works</router-link></li>
                            <li> <router-link :to="{ name: 'marketplace' }">Benefits</router-link></li>
                        </ul>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <h3 class="footer_header">Help</h3>
                        <ul>
                            <li v-for="(item, index) in main_menus" :key="index">
                                <router-link :to="{ name: 'Content', params: { slug: item.slug } }"
                                    v-if="item.type === 'text'">
                                    {{ item.title }}
                                </router-link>
                                <a v-else-if="item.urls" :href="item.urls">{{ item.title }}</a>
                                <a v-else href="#">{{ item.title }}</a>
                            </li>
                        </ul>

                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <h3 class="footer_header">Community</h3>
                        <ul>
                            <li><router-link :to="{ name: 'Fastival' }">Fastival</router-link></li>
                            <li><a href="https://ethnicdish.com/" target="_blank">Blog</a></li>
                        </ul>
                        <br>
                        <h3 class="footer_header">Contact Us</h3>
                        <p>
                            6266 S Congress Ave STE L4, Lake Worth, FL 33462, United States<br>
                            +1-800-484-4403<br>
                            social@ethnicmeal.com<br>
                            Powered By Ethnic Meal
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom_footer">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <p>
                        <img src="../../assets/us-flag.png" alt="us flag" />
                        United States &nbsp;|&nbsp; English (US) &nbsp;|&nbsp; $ (USD)
                    </p>
                </div>
                <div class="col-12 col-sm-4">
                    <ul class="social-link">
                        <li class="twitter">
                            <a href="https://twitter.com/EthnicMeal" aria-label="Ethnic meal twitter (X) page link"
                                target="_blank" title="" data-original-title="Twitter">
                                <i class="fa-brands fa-twitter"></i>
                            </a>
                        </li>
                        <li class="twitter">
                            <a href="https://www.linkedin.com/company/ethnic-meal/?viewAsMember=true"
                                aria-label="Ethnic meal linked page link" target="_blank" title=""
                                data-original-title="Linkedin">
                                <i class="fa-brands fa-linkedin-in"></i>
                            </a>
                        </li>

                        <li class="facebook">
                            <a href="https://www.facebook.com/profile.php?id=61551304885050"
                                aria-label="Ethnic meal facebook page link" target="_blank" title=""
                                data-original-title="Facebook">
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                        <li class="youtube">
                            <a href="https://www.youtube.com/channel/UC05TaeDJVnIfU0gDIDNO92A"
                                aria-label="Ethnic meal youtube channel link" target="_blank" title=""
                                data-original-title="Youtube">
                                <i class="fa-brands fa-youtube"></i>
                            </a>
                        </li>
                        <li class="instagram">
                            <a href="https://www.instagram.com/ethnicmeal2/"
                                aria-label="Ethnic meal instagram page link" target="_blank" title=""
                                data-original-title="Instagram">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-sm-2">
                    <p class="text-right">&copy;2023 Ethnic Meal</p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import '../../assets/js/bootstrap.min.js'
import '../../assets/js/scripts.js'
import axios from '@/utils/axiosConfig';

export default {
    name: 'Footer',
    data() {
        return {
            error: '',
            main_menus: []
        }
    },
    mounted() {
        this.getMenus();
    },
    methods: {
        getMenus() {
            axios.get(`menus`).then((response) => {
                if (response.data.success) {
                    this.main_menus = response.data.data;
                    // console.log(this.main_menus)
                }
                else {
                    this.error = 'URL Data Not Found';
                }
            });
        },
    },
};
</script>