<template>
  <Header />  
  <router-view :key="$route.fullPath" />
  <ChatComponent v-if="chatVisible" />
  <div v-if="footerVisible">
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/include/Header.vue';
import Footer from '@/components/include/Footer.vue';
import ChatComponent from '@/components/ChatComponent.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ChatComponent,
  },
  data() {
    return {
      footerVisible: false, // Controls Footer visibility
      chatVisible: false,   // Controls ChatComponent visibility
    };
  },
  created() {
    // Clear storage after 1 hour
    setTimeout(() => {
      localStorage.clear();
      console.log("Storage Cleared");
    }, 1000 * 60 * 60);

    // Delay showing ChatComponent
    setTimeout(() => {
      this.chatVisible = false;
    }, 500); // Adjust delay for ChatComponent

    // Delay showing Footer
    setTimeout(() => {
      this.footerVisible = true;
    }, 1000); // Adjust delay for Footer
  },
};
</script>
