import $ from 'jquery'
$(document).ready(function () {
    $('.menu-navbar-toggler').on('click',function () {
        $('.menu-navbar-collapse').slideToggle(300);
    });

    smallScreenMenu();
    let temp;

    function resizeEnd() {
        smallScreenMenu();
    }

    $(window).on('resize', function () {
        clearTimeout(temp);
        temp = setTimeout(resizeEnd, 100);
        resetMenu();
    });
});


const subMenus = $('.sub-menu');
const menuLinks = $('.menu-link');

function smallScreenMenu() {
    if ($(window).innerWidth() <= 992) {
        $('.menu-link').each(function (item) {
            $(this).on('click',function () {
                $(this).next().slideToggle();
            });
        });
    } else {
        $('.menu-link').each(function (item) {
            $(this).off('click');
        });
    }
}

function resetMenu() {
    if ($(window).innerWidth() > 992) {
        $('.sub-menu').each(function (item) {
            $(this).css('display', 'none');
        });
    }
}