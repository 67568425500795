import {
  createStore
}
from 'vuex'
// import axios from '@/utils/axiosConfig';
// import $ from 'jquery'
// import router from "@/router";
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import cart from './modules/cart';

export default createStore({
  modules: {
    cart: cart,
    auth: auth
  },
  plugins: [createPersistedState()]
})